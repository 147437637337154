import Ukiyo from "ukiyojs";
import $ from "jquery";
import Flip from "gsap/Flip";
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Manipulation,
  EffectCoverflow,
} from "swiper";
import { Core } from "@unseenco/taxi";
import { Renderer } from "@unseenco/taxi";
import { Transition } from "@unseenco/taxi";
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, Draggable, Flip, MotionPathPlugin);
var innerHeight = require("ios-inner-height");
require("intersection-observer");
import Lenis from "@studio-freight/lenis";
import hideOnScroll from "hide-on-scroll";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import Splitting from "splitting";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import DeviceDetector from "device-detector-js";
Swiper.use([Navigation, Pagination, Autoplay, Manipulation, EffectCoverflow]);
import { Loader } from "@googlemaps/js-api-loader";
/**************** image parallax and video loaded  ************** */
const deviceDetector = new DeviceDetector();
const userAgent = navigator.userAgent;
const machine = deviceDetector.parse(userAgent);

gsap.config({ nullTargetWarn: false });
/******************************** media matches **********************************/

const analytics = Analytics({
  app: "awesome-app",
  plugins: [
    googleAnalytics({
      measurementIds: ["G-9Q3H2GYQSZ"],
    }),
  ],
});
var _gaq = _gaq || [];
_gaq.push(["_setAccount", "UA-20853260-2"]);
_gaq.push(["_trackPageview"]);
(function () {
  var ga = document.createElement("script");
  ga.type = "text/javascript";
  ga.async = true;
  ga.src =
    ("https:" == document.location.protocol ? "https://ssl" : "http://www") +
    ".google-analytics.com/ga.js";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(ga, s);
})();
/*************** */
const mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
const tablet = window.matchMedia("(min-width: 769px) and (max-width: 1024px)");
const desktop = window.matchMedia("(min-width: 1025px)");

if (mobile.matches) {
  $("body").addClass("smartphone");
}

if (desktop.matches) {
  $("body").addClass("desktop");
}

if (mobile.matches) {
  const remove = [...document.querySelectorAll(".removeOnMobile")];
  remove.forEach((el) => {
    el.remove();
  });

  const removeV = [...document.querySelectorAll(".onlyVid")];
  removeV.forEach((el) => {
    el.remove();
  });
}

const videoWrapper2 = document.getElementById("videoWrapper");

if (videoWrapper2 && videoWrapper2.hasAttribute("disable") == false) {
  const mobSrc = document.getElementById("mobSrc").getAttribute("srcset");
  const defSrc = document.getElementById("defSrc").getAttribute("srcset");
  const pageTemplate = $("article").data("template");
  if (mobile.matches) {
    var srcV = mobSrc;

    var poster = "/" + pageTemplate + "_poster_mob.jpg";
  }
  if (desktop.matches) {
    var srcV = defSrc;
    var poster = "/" + pageTemplate + "_poster_def.jpg";
  }
  videoWrapper2.style.backgroundImage = "url('" + poster + "')";
  gsap.to(videoWrapper2, {
    ease: "easeInOutCirc",

    opacity: 1,

    duration: 1.7,
  });
}

/******************************** media matches **********************************/
function makeBottomSWiper() {
  const mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
  const tablet = window.matchMedia(
    "(min-width: 769px) and (max-width: 1024px)"
  );
  const desktop = window.matchMedia("(min-width: 1025px)");

  if (mobile.matches) {
    const defSwipe = new Swiper(".inLineSwiper", {
      speed: 1200,
      spaceBetween: 20,
      freeMode: true,
      centeredSlides: true,
      slidesPerView: "auto",
      // effect: 'coverflow',
      // coverflowEffect: {
      //   rotate: 50,
      //   stretch: 0,
      //   depth: 150,
      //   modifier: 1,
      // },
    });
  } else {
    const defSwipe = new Swiper(".inLineSwiper", {
      speed: 1200,

      slidesPerView: "auto",
    });
  }
}
function animH1() {
  // const h1headline = document.querySelectorAll('h1')
  // const resultsWordsh1 = Splitting({
  //   target: h1headline,
  //   by: 'chars',
  // })
  // {
  //   const czechChar = [...document.querySelectorAll('.czech')]
  //   if (czechChar) {
  //     var czechTl = gsap.timeline({ paused: true })
  //     czechChar.forEach((czechChar) => {
  //       czechTl.to(czechChar, {
  //         opacity: 1,
  //         ease: 'ExpoScaleEase',
  //         duration: 0.7,
  //         translateY: '0%',
  //         onComplete: () => {
  //           console.log('czech')
  //         },
  //       })
  //     })
  //   }
  //   const h1 = [...document.querySelectorAll('h1')]
  //   h1.forEach((h1) => {
  //     const el = [h1.querySelectorAll('.word .char')]
  //     var tl = gsap.timeline({ paused: true })
  //     el.forEach((el) => {
  //       tl.to(
  //         el,
  //         {
  //           // ease: "back",
  //           opacity: 1,
  //           ease: 'ExpoScaleEase',
  //           translateY: '5px',
  //           duration: 0.7,
  //           rotate: 0,
  //           scale: 1,
  //           stagger: 0.025,
  //           onComplete: () => {
  //             czechTl.play()
  //           },
  //         },
  //         '+=0.1'
  //       )
  //     })
  //     gsap.timeline({
  //       scrollTrigger: {
  //         trigger: h1,
  //         onEnter: () => {
  //           tl.play()
  //         },
  //       },
  //     })
  //   })
  // }
}

{
}

function swiperMode() {
  const mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
  const tablet = window.matchMedia(
    "(min-width: 769px) and (max-width: 1024px)"
  );
  const desktop = window.matchMedia("(min-width: 1025px)");
  const el = document.querySelectorAll(".aboutSlider");

  el.forEach((el) => {
    if (machine.device.type !== "desktop") {
      var columnSlider = new Swiper(".aboutSlider", {
        speed: 1200,
        centeredSlides: true,
        loop: true,
        slidesPerView: "auto",

        freeMode: true,
      });

      // console.log('swiperDone')
    }
  });
}
function swiperModeActivity() {
  const mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
  const tablet = window.matchMedia(
    "(min-width: 769px) and (max-width: 1024px)"
  );
  const desktop = window.matchMedia("(min-width: 1025px)");

  const el = document.querySelectorAll(".activitySlider");

  el.forEach((el) => {
    var activitySlider = new Swiper(".activitySlider", {
      speed: 1200,

      centeredSlides: true,
      freeMode: true,
      slidesPerView: "auto",
      // effect: 'cards',

      on: {
        slideChange: function () {
          const index_currentSlide = activitySlider.realIndex;
          const currentSlide = activitySlider.slides[index_currentSlide];
          const prevSlider = activitySlider.slides[index_currentSlide - 1];
          const nextSlider = activitySlider.slides[index_currentSlide + 1];
          const video = currentSlide.querySelector(".imageVideo");
          const prevVideo = prevSlider.querySelector(".imageVideo");
          const nextVideo = nextSlider.querySelector(".imageVideo");
          if (video) {
          }
          if (prevVideo) {
          }
          // console.log(index_currentSlide)
          // console.log(prevVideo);
          // currentSlide.style.background = "red";
          // prevSlider.style.background = "blue";
          // nextSlider.style.background = "green";
        },
        init: function () {
          // console.log('init')
        },
      },
    });
  });
}
function init() {
  {
    const scroller = [...document.querySelectorAll(".scroller")];
    scroller.forEach((el) => {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",
        onComplete: () => {
          $(".sticky").removeClass("hideMenu");
        },
        scale: 1,

        opacity: 0,
      });
    });
  }

  {
    // $('p').each(function (index, value) {
    //   var str = $(this).html()
    //   str = str.replace(
    //     /(\s|^)(a|i|k|o|s|u|v|z)(\s+)([^\p{Cc}\p{Cf}\p{zL}\p{Zp}]+)/gim,
    //     '$1$2&nbsp;$4'
    //   )
    //   $(this).html(str)
    // })
    // const el = [...document.querySelectorAll('.getLinks .imageItem .inItem')]
    // el.forEach((el) => {
    //   var link = $(el).find('a').attr('href')
    //   var a = document.createElement('a')
    //   a.href = link
    //   a.classList.add('fakeLink')
    //   el.append(a)
    //   // console.log(link);
    // })
  }

  {
    const el = [...document.querySelectorAll(".inContent")];

    el.forEach((el) => {
      const video = el.querySelector("video");
      // console.log(video)
      if (video) {
        // console.log(video)
        el.addEventListener("mouseover", () => {
          var playPromise = video.play();
          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
                // We can now safely pause video...
                video.play();
                console.log("thi is play");
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
              });
          }
        });

        el.addEventListener("mouseout", () => {
          var playPromise2 = video.play();
          if (playPromise2 !== undefined) {
            playPromise2
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
                // We can now safely pause video...
                video.pause();
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
              });
          }
        });
      }
    });
  }
  {
    var defSwipe = new Swiper(".doSlide", {
      speed: 1200,
      loop: true,
      spaceBetween: 0,
      slidesPerView: "auto",
      centeredSlides: true,
      freeMode: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
    });
  }
  {
    const defSwipe = new Swiper(".fullSlider", {
      speed: 1200,

      spaceBetween: 20,
      centeredSlides: true,
      slidesPerView: "auto",
    });
  }
  {
    const defSwipe = new Swiper(".mobileSwiper", {
      speed: 1200,
      spaceBetween: 20,
      freeMode: true,
      centeredSlides: true,
      slidesPerView: "auto",
      // effect: 'coverflow',
      // coverflowEffect: {
      //   rotate: 50,
      //   stretch: 0,
      //   depth: 150,
      //   modifier: 1,
      // },
      // speed: 1200,

      // centeredSlides: true,
      // slidesPerView: 'auto',
      // effect: 'coverflow',
      // coverflowEffect: {
      //   rotate: 50,
      //   stretch: 0,
      //   depth: 100,
      //   modifier: 1,
      //   slideShadows: true,
      // },
    });
  }

  {
    const defSwipe = new Swiper(".refSwiper", {
      speed: 1200,
      autoplay: {
        delay: 3000,
      },
      slidesPerView: "auto",
    });
  }

  {
    const defSwipe = new Swiper(".doSlideUbytovani", {
      speed: 1200,

      spaceBetween: 0,
      slidesPerView: "auto",
      centeredSlides: true,
      freeMode: true,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
    });
  }
  {
    const el = [...document.querySelectorAll(".gallerySwiper")];
    el.forEach((el) => {
      const el = new Swiper(el, {
        autoplay: { delay: 5000 },
        keyboard: { enabled: true },
        speed: 1200,

        centeredSlides: true,
        loop: true,
        // effect: 'coverflow',
        // coverflowEffect: {
        //   rotate: 50,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows: true,
        // },

        breakpoints: {
          300: {
            slidesPerView: 1.2,
          },
          768: {
            slidesPerView: 2,
          },
          1600: {
            slidesPerView: 1.8,
          },
        },
      });
    });
  }

  {
    const makeInsta = new Swiper(".sada", {});
  }
  {
    const el = document.querySelectorAll(".zoomIn");

    el.forEach((el) => {
      // $(el).attr("src", $(el).attr("data-src"));

      gsap.set(el, {
        scale: 0.9,
      });
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "bottom bottom",
          scrub: true,
        },

        scale: 1,

        ease: "easeInOutCirc",
        duration: 2,
      });
    });
  }

  // {
  //   const el = document.querySelectorAll('.imageItem video')

  //   el.forEach((el) => {
  //     new Ukiyo(el, {
  //       speed: 1.2,
  //       scale: 1.4,
  //       willChange: true,
  //     })
  //   })
  // }

  {
    const animChar = [...document.querySelectorAll("p")];
    animChar.forEach((el) => {
      gsap.set(el, { scale: 0.88, opacity: 0 }),
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
          },
          ease: "ExpoScaleEase",

          scale: 1,
          delay: 0.1,
          opacity: 1,
          duration: 1.2,
        });
    });
  }

  /**************** curtains stuff ************** */

  const inH = innerHeight();

  const inHH = inH / 2;

  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${innerHeight()}px`);
  };
  const appHeightResize = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height-resize", `${innerHeight()}px`);
  };

  window.addEventListener("resize", appHeightResize);
  addEventListener("resize", (event) => {
    const ef = document.body.innerWidth;
  });

  appHeight();
  appHeightResize();
}

const noise = () => {
  let canvas, ctx;
  let wWidth, wHeight;
  let noiseData = [];
  let frame = 0;
  let loopTimeout;
  // Create Noise
  const createNoise = () => {
    const idata = ctx.createImageData(wWidth, wHeight);
    const buffer32 = new Uint32Array(idata.data.buffer);
    const len = buffer32.length;
    for (let i = 0; i < len; i++) {
      if (Math.random() < 0.1) {
        buffer32[i] = 0xff000000;
      }
    }
    noiseData.push(idata);
  };
  // Play Noise
  const paintNoise = () => {
    if (frame === 9) {
      frame = 0;
    } else {
      frame++;
    }
    ctx.putImageData(noiseData[frame], 0, 0);
  };
  // Loop
  const loop = () => {
    paintNoise(frame);
    loopTimeout = window.setTimeout(() => {
      window.requestAnimationFrame(loop);
    }, 1000 / 25);
  };
  // Setup
  const setup = () => {
    wWidth = window.innerWidth;
    wHeight = window.innerHeight;
    canvas.width = wWidth;
    canvas.height = wHeight;
    for (let i = 0; i < 10; i++) {
      createNoise();
    }
    loop();
  };
  // Reset
  let resizeThrottle;
  const reset = () => {
    window.addEventListener(
      "resize",
      () => {
        window.clearTimeout(resizeThrottle);
        resizeThrottle = window.setTimeout(() => {
          window.clearTimeout(loopTimeout);
          setup();
        }, 200);
      },
      false
    );
  };
  // Init
  const init = (() => {
    canvas = document.getElementById("noise");
    ctx = canvas.getContext("2d");
    setup();
  })();
};
noise();
{
  var playBTN = document.getElementById("play");

  if (playBTN) {
    $(playBTN).click(function (event) {
      event.preventDefault();
      pPlay();
      this.remove();
    });
  }

  var navBtn = document.getElementById("menuTrigger");

  $(navBtn).click(function (event) {
    event.preventDefault();
    pPlay();
    if ($(navBtn).hasClass("open") == true) {
      navClose.restart();
    } else {
      navOpen.restart();
    }
  });

  const height = $(".menuOverlay").height();
  const navMenu = [...document.querySelectorAll(".menuOverlay")];
  const navOverlay = [...document.querySelectorAll(".menuOver")];
  const menuP = [...document.querySelectorAll(".menuItemsHolder .small")];

  var navClose = gsap.to(navMenu, {
    duration: 0.7,
    paused: true,

    ease: "easeInOutCubic",
    translateY: "-100%",
    onComplete: () => {
      $(navBtn).removeClass("open");
      $("body").removeClass("menuOpen");
    },
  });
  var navOverLay = gsap.to(navOverlay, { paused: true, opacity: 0 });

  const mainBox = document.getElementById("deNiro");

  var navOpen = gsap.to(navMenu, {
    duration: 0.7,
    paused: true,

    ease: "easeInOutCubic",
    translateY: 0,
    onStart: () => {
      $(navBtn).addClass("open");
      $(navBtn).addClass("invert");
      $("body").addClass("menuOpen");
      navlinksA.play();
      menuPP.play();
    },
  });

  const navlinks = [...document.querySelectorAll(".menuItemsHolder a")];

  var navlinksA = gsap.fromTo(
    navlinks,
    {
      opacity: 0,
    },
    {
      paused: true,
      opacity: 1,
      duration: 3,
      ease: "easeInOutCirc",
    },
    "+=0.1"
  );

  var menuPP = gsap.fromTo(
    menuP,
    {
      opacity: 0,
    },
    {
      paused: true,
      opacity: 1,
      duration: 2,
      ease: "easeInOutCirc",
    },
    "+=0.1"
  );
}

const doc = document.documentElement;
const colorScreen = document.getElementById("colorHolderTemp");

function changeBodyColor() {
  const template = document.querySelector("[data-taxi-view]");
  const bgColor = getComputedStyle(template).getPropertyValue("--bgColor");
  const txtColor = getComputedStyle(template).getPropertyValue("--txtColor");
  doc.style.setProperty("--bgColor-button", bgColor);
  doc.style.setProperty("--txtColor-button", txtColor);

  gsap.to("body", {
    backgroundColor: bgColor,
    duration: 2,

    ease: "easeInOutCirc",
    immediateRender: false,
    onComplete: () => {
      // console.log('body color CHANGED')
    },
  });
}

function getNewColor() {
  const template = document.querySelector("[data-taxi-view]");
  const bgColor = getComputedStyle(template).getPropertyValue("--bgColor");

  colorScreen.style.setProperty("--bgColor-temp", bgColor);
  gsap.set(colorScreen, {
    backgroundColor: bgColor,
  });
  gsap.to(colorScreen, {
    translateY: 0,
    backgroundColor: bgColor,
    duration: 0.7,
    ease: "easeInOutCirc",
    immediateRender: false,
    onComplete: () => {},
  });
}

function videoPageRender() {
  const ppp = [...document.querySelectorAll(".onPage")];
  ppp.forEach((ppp) => {
    gsap.to(ppp, {
      ease: "easeInOutCirc",
      scale: 1,
      opacity: 1,
      translateY: 0,
      duration: 1.8,
    });
  });
  const page = document.getElementById("pageContent");
  gsap.set(page, {
    opacity: 0,
  });
  gsap.to(page, {
    ease: "easeInOutCirc",
    translateY: 0,
    opacity: 1,

    duration: 1.0,
  });
}

function videoTransition() {
  const homeTrigger = document.getElementById("homeIntroTrigger");
  gsap.set("#videoTransition", {
    opacity: 0,
  });
  gsap.to("#videoTransition", {
    scrollTrigger: {
      trigger: homeTrigger,
      scrub: true,
      start: "top bottom",
      end: "top top",
    },
    // "-webkit-filter": "brightness(60%)",
    // filter: "brightness(60%)",
    opacity: 0,
    duration: 1.0,
  });
  gsap.to(".scroller", {
    scrollTrigger: {
      trigger: homeTrigger,
      scrub: true,
      start: "top bottom",
      end: "top center",
    },
    translateY: "-15%",
    // "-webkit-filter": "brightness(60%)",
    // filter: "brightness(60%)",
    opacity: 1,
    duration: 1.0,
  });
}

export class CustomRenderer extends Renderer {
  onEnter() {
    dataPositionOnce();
    dataPosition();
    responsiveVideo(1);
    dataScale();
    init();

    changeBodyColor();

    // run after the new content has been added to the Taxi container
  }

  onEnterCompleted() {
    doParallax();
    swiperMode();
    swiperModeActivity();
    makeBottomSWiper();
    animH1();
    getInsta();
    makeMapa();
    pPlay();
    // run after the transition.onEnter has fully completed
  }

  onLeave() {
    removePlayer();
    if ($(navBtn).hasClass("open") == true) {
      navClose.restart();
    }
    // run before the transition.onLeave method is called
  }

  onLeaveCompleted() {
    // run after the transition.onleave has fully completed
  }
}

export class MyTransition extends Transition {
  /**
   * Handle the transition leaving the previous page.
   * @param { { from: HTMLElement, trigger: string|HTMLElement|false, done: function } } props
   */
  onLeave({ from, trigger, done }) {
    const inH = innerHeight();
    inH = inH / 2;
    gsap.to("main", {
      autoAlpha: 0,
      ease: "easeInOutCirc",
      duration: 1.2,

      onComplete: done,
    });
  }

  /**
   * Handle the transition entering the next page.
   * @param { { to: HTMLElement, trigger: string|HTMLElement|false, done: function } } props
   */
  onEnter({ to, trigger, done }) {
    gsap.fromTo(
      "main",
      { autoAlpha: 0 },
      {
        autoAlpha: 1,
        duration: 0.7,
        ease: "easeInOutCirc",

        onComplete: done,
      }
    );
  }
}
const taxi = new Core({
  transitions: {
    default: MyTransition,
  },
  renderers: {
    default: CustomRenderer,
  },
  links: "a:not([target]):not([href^=\\#]):not([data-taxi-ignore]), a.fakeLink",
});
// taxi.updateCache()
// taxi.clearCache('/')
taxi.preload("/");
/***********************m ReSPONSIZVE VIDEO   ************************************/

function BAKresponsiveVideo() {
  const mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
  const tablet = window.matchMedia(
    "(min-width: 769px) and (max-width: 1024px)"
  );
  const desktop = window.matchMedia("(min-width: 1025px)");

  const videoWrapper = document.getElementById("videoWrapper");

  const ifvideo = videoWrapper.querySelector("video");
  if (ifvideo) {
    ifvideo.remove();
  }

  if (videoWrapper) {
    if (videoWrapper.hasAttribute("disable") == false) {
      const mainVideo = $("#responsiveVideo");
      const mobSrc = document.getElementById("mobSrc").getAttribute("srcset");
      const defSrc = document.getElementById("defSrc").getAttribute("srcset");
      const pageTemplate = $("article").data("template");

      const video = document.createElement("video");
      video.controls = false;
      video.setAttribute("playsinline", "true");
      video.setAttribute("playsinlinecontrols", "false");
      video.muted = true;
      video.loop = true;
      video.autoplay = true;
      video.preload = "auto";
      video.setAttribute("id", "responsiveVideo");
      video.setAttribute("class", "video");

      const videoSrc = "";

      if (mobile.matches) {
        video.src = mobSrc;
        video.setAttribute("poster", "/" + pageTemplate + "_poster_mob.jpg");
        video.canPlayType("video/mp4");
      }
      if (desktop.matches) {
        video.src = defSrc;
        video.setAttribute("poster", "/" + pageTemplate + "_poster_def.jpg");
        video.canPlayType("video/mp4");
      }

      videoWrapper.appendChild(video);
      video.play();
    }
    var vWraP = gsap.timeline({ paused: true });
    vWraP.to(videoWrapper, {
      ease: "easeInOutCirc",
      onStart: () => {
        mainVid.play();
      },
      scale: 1,
      opacity: 1,
      delay: 0.2,
      duration: 1.1,
    });

    var mainVid = gsap.timeline({ paused: true });
    mainVid.to("#responsiveVideo", {
      ease: "easeInOutCirc",
      scale: 1,
      opacity: 1,
      delay: 0,
      duration: 1.7,
    });

    vWraP.play();

    videoPageRender();
    videoTransition();
    gsap.set(videoWrapper, {
      opacity: 0,
      scale: 1,
    });
  }
}

function smooooooth() {
  const lenis = new Lenis({
    duration: 1.8,
    easing: "easeOutBounce",
    direction: "vertical", // vertical, horizontal
    gestureDirection: "vertical", // vertical, horizontal, both
    smooth: true,
    lerp: 0.1,
    mouseMultiplier: 0.7,
    smoothTouch: false,
    touchMultiplier: 2,
    infinite: false,
  });
  //get scroll value
  lenis.on("scroll", ({ scroll, limit, velocity, direction, progress }) => {});
  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }
  requestAnimationFrame(raf);
}
/* On Load
 **************************************************************/
window.addEventListener("load", function () {
  smooooooth();
  makeBottomSWiper();
});

taxi.on("NAVIGATE_IN", ({ to }) => {
  window.scrollTo(0, 0);
});

/* On Resize
 **************************************************************/

window.addEventListener("resize", function () {
  swiperMode();
  swiperModeActivity();
  makeBottomSWiper();
  dataPosition();
  dataPositionOnce();
  refreshParallax();
  dataScale();
});

// function refreshVideo() {
//   const el = [...document.querySelectorAll('.playVideo')]

//   el.forEach((el) => {
//     // el.play()
//   })
// }
/***********************m ReSPONSIZVE VIDEO   ************************************/
// let st = ScrollTrigger.create({
//   trigger: '.onPage',
//   pin: '.homeIntro',
//   start: 'top top',
//   end: 'bottom top',
// })

function refreshParallax() {
  const mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
  const tablet = window.matchMedia(
    "(min-width: 769px) and (max-width: 1024px)"
  );
  const desktop = window.matchMedia("(min-width: 1025px)");
  // if (desktop.matches) {
  //   const el = document.querySelectorAll('.imageItem:not(.removeParalax) img')

  //   el.forEach((el) => {
  //     if (el) {
  //       instance.reset()
  //     }
  //   })
  //   {
  //     const el = document.querySelectorAll('.parallaxImg')

  //     el.forEach((el) => {
  //       instance.reset()
  //     })
  //   }
  // }

  doParallax();
}

function doParallax() {
  const mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
  const tablet = window.matchMedia(
    "(min-width: 769px) and (max-width: 1024px)"
  );
  const desktop = window.matchMedia("(min-width: 1025px)");
  if (desktop.matches) {
    const el = document.querySelectorAll(".imageItem:not(.removeParalax) img");

    el.forEach((el) => {
      const instance = new Ukiyo(el, {
        speed: 1.4,
        scale: 1.2,
      });
    });
    {
      const el = document.querySelectorAll(".parallaxImg");

      el.forEach((el) => {
        new Ukiyo(el, {
          speed: 1.1,
          scale: 1.2,
        });
      });
    }
  }
}
function dataPositionOnce() {
  {
    if (machine.device.type == "desktop") {
      const el = [...document.querySelectorAll("[data-position-once]")];
      el.forEach((el) => {
        const posArray = el.getAttribute("data-position-once").split(" ");

        var unit = "%";

        if (posArray[1]) {
          var to = posArray[1];
          var end = "top center";
        } else {
          var to = -Math.abs(posArray[0]);
          var end = "bottom top";
        }

        var from = posArray[0];

        if (posArray[2]) {
          unit = posArray[2];
        }
        // console.log(posArray[0]);
        // console.log(posArray[1]);

        // console.log(negative);
        gsap.set(el, {
          opacity: 1,
          translateY: from + unit,
          // width: width + "%",
        }),
          gsap.to(el, {
            scrollTrigger: {
              trigger: el,
              scrub: false,
              start: "top bottom",
              end: "center center",
            },
            duration: 2.7,
            translateY: to + unit,
            stagger: {
              each: 0.2,

              repeat: 0,
            },
          });
      });
    }
  }
}
function dataPosition() {
  {
    if (machine.device.type == "desktop") {
      const el = [...document.querySelectorAll("[data-set-position]")];
      el.forEach((el) => {
        var unit = "%";
        const posArray = el.getAttribute("data-set-position").split(" ");
        const set = posArray[0];
        if (posArray[1]) {
          var pad = 0;
        } else {
          var pad = posArray[0];
        }
        gsap.set(el, {
          opacity: 1,
          marginBottom: pad + unit,
          translateY: set + unit,
        });
      });
    }
  }
  {
    {
      if (machine.device.type == "desktop") {
        const el = [...document.querySelectorAll("[data-position]")];
        el.forEach((el) => {
          const posArray = el.getAttribute("data-position").split(" ");

          var unit = "%";

          if (posArray[1]) {
            var to = posArray[1];
            var end = "top center";
          } else {
            var to = -Math.abs(posArray[0]);
            var end = "bottom top";
          }

          if (posArray[0] == "50vh") {
            var from = window.innerHeight / 2;
            unit = "";
          } else {
            var from = posArray[0];
          }

          if (posArray[3]) {
            unit = posArray[3];
          }
          // console.log(posArray[0]);
          // console.log(posArray[1]);

          // console.log(negative);
          gsap.set(el, {
            opacity: 1,
            translateY: from + unit,
            // width: width + "%",
          }),
            gsap.to(el, {
              scrollTrigger: {
                trigger: el,
                scrub: true,
                start: "top bottom",
                end: end,
              },

              translateY: to + unit,
              duration: 1.2,
              stagger: {
                each: 0.2,

                repeat: 0,
              },
            });
        });
      }
    }
  }
}

function dataScale() {
  {
    const mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
    const tablet = window.matchMedia(
      "(min-width: 769px) and (max-width: 1024px)"
    );
    const desktop = window.matchMedia("(min-width: 1025px)");

    const el = [...document.querySelectorAll(".none [data-scale] .imageRow")];
    el.forEach((el) => {
      // console.log(posArray[0]);
      // console.log(posArray[1]);

      // console.log(negative);
      gsap.set(el, {
        opacity: 0,
        scale: 0.9,
        // width: width + "%",
      }),
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
            // scrub: true,
            // start: 'top bottom',
            // end: 'center center',
          },
          duration: 0.85,
          scale: 0.9,
          opacity: 1,
          stagger: {
            each: 0.5,
            repeat: 0,
          },
        });
    });
  }
}

gsap.to("#pageContent", {
  scrollTrigger: {
    trigger: "#pageContent",
    start: "top bottom",
    end: "top center",
  },
  onComplete: () => {
    playVid();
  },
});

function playVid() {
  //   const videoWrapper = document.getElementById('videoWrapper')
  //   const ifvideo = videoWrapper.querySelector('video')
  //   if (videoWrapper.hasAttribute('disable') == false) {
  //     ifvideo.play()
  //   }
  //   // console.log(ifvideo)
}

{
}

{
  const el = document.querySelectorAll(".parallaxVideo");

  el.forEach((el) => {
    new Ukiyo(el, {
      speed: 1.3,
      scale: 1.3,
    });
  });
}

$(window).scroll(function () {
  var scroll = $(document).scrollTop();
  if (scroll > window.innerHeight) {
    $("#menuTrigger").addClass("invert");
  } else {
    $("#menuTrigger").removeClass("invert");
  }
});
makeMapa();

async function getInsta() {
  const pageTemplate = $("article").data("template");
  const mycaption = "";
  switch (pageTemplate) {
    case "bear":
      mycaption = "#alaskabear";
      break;
    case "denali":
      mycaption = "#alaskaflightseeing";
      break;
    case "rybareni":
      mycaption = "#alaskafishing";
      break;
    case "velryby":
      mycaption = "#whalealaska";
      break;
    case "rafting":
      mycaption = "#alaskarafting";
      break;
    case "vyprava":
      mycaption = "#alaskaglacier";
      break;
    case "trekking":
      mycaption = "#alaskahiking";
      break;
    case "kajaky":
      mycaption = "#alaskaseakayaking";
      break;
    case "quad":
      mycaption = "#alaskaatv";
      break;
    case "havaj":
      mycaption = "#havaj";
      break;
    case "zimni":
      mycaption = "#alaskainwinter";
      break;
    case "ubytovani":
      mycaption = "#nasdum_mwa";
      break;
    default:
      console.log(`Default instagram feed`);
  }
  console.log(mycaption);

  const token2 =
    "EAAMDHvyDKH0BO7Jm33bMnX2kVuwoDrVLyvrpqNNRZCqnBGWy3X71LkX2AAM9vgJLp0WmPxQ6FeZBMQrUogdMH04Udd65D3pXaUl8YS1NlZBDKXxikZCyvGZBuJt5ZCHzAZCVvu5NDhUHcVge6WEaWZBhMequiTRZBZAgLuBpPhSI52D9yNp9mWQFiV6X5zmAZDZD";

  var fields = "media_type,media_url,thumbnail_url,permalink,caption";
  var limit = 200;
  if (pageTemplate == "o-nas") {
    var limit = 200;
  }
  var resp = [];

  $.ajax({
    url:
      "https://graph.instagram.com/me/media?fields=" +
      fields +
      "&access_token=" +
      token2 +
      "&limit=" +
      limit,
    type: "GET",

    success: function (response) {
      for (var x in response.data) {
        var link = response.data[x]["permalink"],
          caption = response.data[x]["caption"],
          image = response.data[x]["media_url"],
          image_video = response.data[x]["thumbnail_url"],
          html = "";

        if (response.data[x]["media_type"] == "VIDEO") {
        } else {
          if (caption.includes(mycaption)) {
            var regexp = /\#\w\w+\s?/g;
            caption = caption.replace(regexp, "");

            html += '<div class="swiper-slide">';

            html += '<div class="swiperContent">';

            html +=
              '<div class="imgDiv colFlexCenter"><div class="igImg "><div><a href="' +
              link +
              '" target="_blank"><img src="' +
              image +
              '" loa' +
              'ding="" class="lazyload insta-image" /></a></div> </div><div class="igDesc"><p class="petit left">' +
              caption +
              "</p></div></div>";
          }
        }
        $("#feedMe").append(html);
      }
      createInstaSlider();
    },
  });
}
function createInstaSlider() {
  const defSwipe = new Swiper(".makeInsta", {
    speed: 1200,
    loop: true,
    spaceBetween: 20,
    slidesPerView: "auto",
    centeredSlides: true,
    freeMode: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      300: {
        slidesPerView: 1.4,
      },
      768: {
        slidesPerView: 3.5,
      },
      1600: {
        slidesPerView: 4.5,
      },
      1900: {
        slidesPerView: 5.5,
      },
    },

    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  });
}

function responsiveVideo(param) {
  gsap.set("h1", {
    opacity: 0,
  });
  gsap.to("h1", {
    ease: "ExpoScaleEase",
    scale: 1,
    opacity: 1,
    delay: 0.1,
    duration: 1.2,
  });
  window.scrollTo(0, 0);

  const videoObj = document.getElementById("responsiveVideo");
  const pageTemplate = $("article").data("template");

  const videoWrapper = document.getElementById("videoWrapper");

  if (videoWrapper && videoWrapper.hasAttribute("disable") == false) {
    const mobSrc = document.getElementById("mobSrc").getAttribute("srcset");
    const defSrc = document.getElementById("defSrc").getAttribute("srcset");
    gsap.to("#videoWrapper", {
      ease: "easeInOutCirc",

      opacity: 1,

      duration: 2.7,
    });

    var options = {
      fill: true,
      controls: false,
      responsive: false,

      fluid: true,
      navigationUI: "hide",
      resizeManager: false,
    };

    const mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
    const tablet = window.matchMedia(
      "(min-width: 769px) and (max-width: 1024px)"
    );
    const desktop = window.matchMedia("(min-width: 1025px)");

    if (mobile.matches) {
      var srcV = mobSrc;
      // player.src({
      //   type: 'video/mp4',
      //   src: mobSrc,
      // })
      var poster = "/" + pageTemplate + "_poster_mob.jpg";
      // player.poster('/' + pageTemplate + '_poster_mob.jpg')
      // console.log('mobikle')
    }
    if (desktop.matches) {
      var srcV = defSrc;
      var poster = "/" + pageTemplate + "_poster_def.jpg";
      // player.src({
      //   type: 'video/mp4',
      //   src: defSrc,
      // })
      // player.poster('/' + pageTemplate + '_poster_mob.jpg')
      // console.log('mDeds')
    }

    videoWrapper.style.backgroundImage = "url('" + poster + "')";
    var player = videojs(videoObj, options, function onPlayerReady() {
      videojs.log("Your player is ready!");

      // player.poster(poster)
      player.src({
        type: "video/mp4",
        src: srcV,
      });

      makeVidAnim();
      player.responsive(false);
      var playPromise = player.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            gsap.to("#responsiveVideo", {
              ease: "easeInOutCirc",

              opacity: 1,
              scale: 1,
              duration: 0.1,
            });

            gsap.to("#play", {
              ease: "easeInOutCirc",

              opacity: 0,
              scale: 1,
              duration: 0.1,
            });
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }

      // How about an event listener?
      player.on("ended", function () {
        videojs.log("Awww...over so soon?!");
      });
    });
  } else {
    makeVidAnim();
    gsap.set(videoWrapper, {
      opacity: 0,
    });
    gsap.to("#videoWrapper", {
      ease: "easeInOutCirc",

      opacity: 1,

      duration: 2.7,
    });
    // How
  }
}

function removePlayer() {
  const videoObj = document.getElementById("responsiveVideo");

  if (document.getElementById("responsiveVideo")) {
    var playerToremove = videojs(videoObj);

    playerToremove.dispose();
  }
}

function pPlay() {
  const videoObj = document.getElementById("responsiveVideo");

  if (document.getElementById("responsiveVideo")) {
    var playerToremove = videojs(videoObj);

    var playPromise = playerToremove.play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          gsap.to("#responsiveVideo", {
            ease: "easeInOutCirc",

            opacity: 1,
            scale: 1,
            duration: 0.1,
          });
        })
        .catch((error) => {
          // Auto-play was prevented
          // Show paused UI.
        });
    }
  }
}

function makeVidAnim() {
  const desktop = window.matchMedia("(min-width: 1025px)");
  if (desktop.matches) {
    const el = document.querySelectorAll(".zoomInIntro");
    const h1 = document.getElementById("h1h1");
    el.forEach((el) => {
      // $(el).attr("src", $(el).attr("data-src"));
      var top = $(el).offset().top;
      // console.log(el.offsetTop)
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          start: "top " + top,
          // start: 'center center',
          end: "top top",
          scrub: true,

          onComplete: () => {},
        },
        width: window.innerWidth,
        ease: "easeInOutCirc",
        duration: 2,
      });
    });
  }
}

async function showMapa() {
  const loader = new Loader({
    apiKey: "AIzaSyCRxzmj4eleUyOyQLlz2j7FLxdXYQxmMKw",
    version: "weekly",
  });

  const myLatLng = { lat: 59.67248, lng: -151.44659 };

  const mapOptions = {
    center: myLatLng,
    zoom: 12,

    styles: [
      {
        featureType: "all",
        elementType: "geometry.fill",
        stylers: [{ weight: "2.00" }],
      },
      {
        featureType: "all",
        elementType: "geometry.stroke",
        stylers: [{ color: "#9c9c9c" }],
      },
      {
        featureType: "all",
        elementType: "labels.text",
        stylers: [{ visibility: "on" }],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: "#f2f2f2" }],
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [{ saturation: -100 }, { lightness: 45 }],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#7b7b7b" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ visibility: "simplified" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [{ color: "#46bcec" }, { visibility: "on" }],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#c8d7d4" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#070707" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ffffff" }],
      },
    ],
    mapTypeControl: false,
    streetViewControl: false,
    scaleControl: true,
    rotateControl: false,
  };
  const image = "beachflag.png";
  loader.load().then(function (google) {
    const svgMarker = {
      path:
        "M5 8.97266C7.25 8.72363 9 6.81641 9 4.5C9 2.01465 6.98535 0 4.5 0C2.01465 0 0 2.01465 0 4.5C0 6.81641 1.75 8.72363 4 8.97266V23H5V8.97266Z",
      fillColor: "#07211f",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 3,
      anchor: new google.maps.Point(9, 23),
    };

    const map = new google.maps.Map(document.getElementById("map"), mapOptions);

    new google.maps.Marker({
      position: myLatLng,
      map,
      icon: svgMarker,
      title: "U pejska a",
    });
  });
}

function makeMapa() {
  const promis = document.getElementById("map");

  if (promis) {
    showMapa();
  }
}
